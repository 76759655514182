<template>
  <div class="ly_news_cb_main">
      <div class="ly_news_cb_title">细胞DNA提取服务</div>
        <p>DNA提取的原理为裂解细胞，使细胞中的核酸和大分子物质释放出来，然后用一系列的试剂纯化，最后得到DNA的过程。 </p>
        <p>因此避免细胞发生支原体污染后仍进行实验极为重要，所以对细胞的支原体检测极为重要。我们采取QPCR法，一步恒温试剂盒，PCR扩增法等多种方法进行支原体检测。可以保证检测的准确性。</p>

        <div class="ly_news_cb_hot_so">送检测样本要求：</div>
        <p>细胞样本：活细胞（细胞数≥106 cells ）或直接提供细胞沉淀（细胞数≥106 cells ）</p>

        <p>客户提供样本，管壁需标记清楚细胞的名称，数量较多时要标好标注，并附送样清单。</p>

        <p>DNA提取的周期为1周，具体细节请与相关客服人员沟通好。</p>
  </div>
  <footer>
    <ul class="footer-nav">
      <li class="footer-li">
        <a
          href="https://tb.53kf.com/code/client/099be4cda651b04a92a215ad1e6edd8d8/1"
        >
          <!-- <img src="https://www.bzwz.com/static/m/images/icon52.png" alt="" /> -->
          <img src="@/assets/img/common/icon52.png" alt="" />
          客服
        </a>
      </li>

      <li class="footer-li button download" @click="onBack()" style="background-color: #df0024">
        <a href="javascript:;"> 返回 </a>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
import {
  defineComponent
} from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "StrAppraisal",
  setup() {
    const router = useRouter();
    function onBack() {
      router.back()
    }
    return {
      onBack
    };
  },
});
</script>


<style lang="scss" scoped>
.ly_news_cb_main {
  padding: 15px;
  font-size: 0.42667rem;
  line-height: 0.66667rem;
  color: #666;
  padding-bottom: 1.33333rem;
}

p {
  margin: 1em 0;
  word-break: break-word;
  text-indent: 20px;
}

.ly_news_cb_title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #444;
}

.ly_news_cb_hot_so {
  font-weight: 600;
  color: #444;
  font-size: 18px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1px solid #f4f4f4;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}
footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}
.footer-li img {
  display: block;
  width: 19.5px;
  height: 19.5px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -9.75px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #df0024;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 10px;
}
footer .button a {
  color: #fff;
}
.download {
  float: right;
}
</style>